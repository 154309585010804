<template>
  <el-select v-model="selectedValue" :disabled="disabled" :placeholder="placeholder" @change="handleChange" class="turn-select">
    <el-option
      v-for="item in dataList"
      :key="item[valueField]"
      :label="item.name"
      :value="item[valueField]"
      :class="item.pid > 0 ? 'padding-item' : ''"
    ></el-option>
  </el-select>
</template>

<script>
import { listStuTurn } from '@/api/stu/stu_turn'

export default {
  name: 'turn-select',
  props: {
    type: {
      type: String,
      default: () => { return 'turn' }
    },
    valueField: {
      type: String,
      default: () => { return 'id' }
    },
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      dataList: {},
      selectedValue: this.value
    }
  },
  created() {
    listStuTurn().then(response => {
      if (response.code == 0) {
        const dataList = []
        if (this.type == 'turn') {
          this.dataList = response.data.list
        } else {
          for (let i = 0; i < response.data.list.length; i++) {
            const item = response.data.list[i]
            if (item.pid == 0) {
              dataList.push(item)
            }
          }
          this.dataList = dataList
        }
      }
    })
  },
  methods: {
    handleChange(e) {
      this.$emit('input', this.selectedValue)
      this.$emit('change', this.selectedValue)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
<style scoped>
.turn-select {
  width: 100%;
}
.padding-item {
  padding-left: 30px;
}
</style>