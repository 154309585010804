<template>
  <tree-select 
    v-model="selectedValue" 
    :label="label" 
    :defaultLabel="defaultLabel"
    :valueField="valueField"
    :labelField="labelField"
    :placeholder="placeholder"
    :size="size"
    :disabled="disabled"
    :multiple="multiple"
    :checkStrictly="checkStrictly"
    :getDataList="listDept"
    @change="handleChange"
  />
</template>

<script>
import { listDept } from "@/api/core/system/dept";

export default {
  name: 'dept-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    valueField: {
      type: String,
      default: () => { return "id" }
    },
    labelField: {
      type: String,
      default: () => { return "name" }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择归属机构' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    multiple: {
      type: Boolean,
      default: () => { return false }
    },
    checkStrictly: {
      Type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      selectedValue: this.value,
      listDept: listDept
    }
  },
  created() {
  },
  methods: {
    handleChange(e1, e2,e3) {
      this.$emit('input', e1, e2,e3)
      this.$emit('change', e1, e2,e3)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>

<style scoped>
.filter-tree {
  margin-top: 10px;
}
</style>