import request from "@/utils/request";

export function listStuStudent(query) {
  return request({
    url: "/stu/stustudent/list",
    method: "get",
    params: query,
  });
}

export function getStuStudent(id, no) {
  return request({
    url: "/stu/stustudent/get?" + (id ? "id=" + id : "no=" + no),
    method: "get",
  });
}

export function addStuStudent(data) {
  return request({
    url: "/stu/stustudent/add",
    method: "post",
    data: data,
  });
}

export function editStuStudent(data) {
  return request({
    url: "/stu/stustudent/edit",
    method: "post",
    data: data,
  });
}

export function delStuStudent(ids) {
  return request({
    url: "/stu/stustudent/del",
    method: "post",
    data: {
      ids: ids,
    },
  });
}

export function syncAccount(query) {
  return request({
    url: "/stu/stustudent/syncAccount",
    method: "post",
    data: query,
  });
}

export function syncAccountProgress() {
  return request({
    url: "/stu/stustudent/syncAccountProgress",
    method: "get",
  });
}

export function getTokens() {
  let data = {
    pageNum: 1,
    pageSize: 10,
    type: "tsc_print",
  };

  return request({
    url: "/dict/data/list",
    method: "get",
    params: data,
  });
}
export function postPrint(data) {
  return request({
    url: "/form/data/add/43829154416645",
    method: "post",
    data: data,
  });
}

export function getUser(data) {
  // return http.get("/stu/stustudent/get?id=" + data);
  return request({
    url: "/stu/stustudent/get",
    method: "get",
    params: data,
  });
}
export function stustudentEdit(data) {
  return request({
    url: "/stu/stustudent/edit",
    method: "post",
    data: data,
  });
}

export function getArchives(data) {
  return request({
    url: "/form/data/list/43829154416645",
    method: "post",
    data: data,
  });
}

export function changeArchives(data) {
  return request({
    url: "/form/data/edit/43829154416645",
    method: "post",
    data: data,
  });
}
// post /form/xkda/syncprintdata
export function addStudent(data) {
  return request({
    url: "/form/xkda/syncprintdata",
    method: "post",
    data: data,
  });
}