<template>
  <grid-select
    v-model="selectedValue" 
    :label="label" 
    :defaultLabel="defaultLabel"
    :valueField="valueField"
    :labelField="labelField"
    :placeholder="placeholder"
    :size="size" 
    :searchItems="dept_no ? searchItemsWithoutDept: searchItems"
    :gridItems="gridItems"
    :getDataList="listStuMajorFormatter"
    :multiple="multiple"
    :disabled="disabled"
    @change="handleChange"
  ></grid-select>
</template>

<script>
import { listStuMajor } from "@/api/stu/stu_major";

export default {
  name: 'major-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    valueField: {
      type: String,
      default: () => { return "id" }
    },
    labelField: {
      type: String,
      default: () => { return "name" }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    dept_no: {
      type: [String, Number],
      default: () => { return undefined }
    },
    multiple: {
      type: Boolean,
      default: () => { return false }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      selectedValue: this.value,
      searchItems: [{field: 'name', title: '专业名称'}, {field: 'no', title: '专业编码'}, {field: 'dept_no', title: '院系', ctype: 'dept', valueField: 'no'}, {field: 'type', title: '专业类型', ctype: 'dict', cvalue: 'stu_major_type'}],
      searchItemsWithoutDept: [{field: 'name', title: '专业名称'}, {field: 'no', title: '专业编码'}, {field: 'type', title: '专业类型', ctype: 'dict', cvalue: 'stu_major_type'}],
      gridItems: [{field: 'name', title: '专业名称'}, {field: 'no', title: '专业编码'}, {field: 'dept_name', title: '院系'}, {field: 'type', title: '专业类型', ctype: 'dict', cvalue: 'stu_major_type'}]
    }
  },
  created() {
  },
  methods: {
    handleChange(e1, e2) {
      this.$emit('input', e1)
      this.$emit('change', e1, e2)
    },
    listStuMajorFormatter(param) {
      if (this.dept_no) {
        return listStuMajor({
          ...param,
          dept_no: this.dept_no
        })
      }
      return listStuMajor(param)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
